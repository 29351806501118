import React from "react"
import { Descriptions, Divider } from "antd"
import Layout from "../../../components/headerFooterLayout"
import { PostShowPageProps } from "@richsoni/gatsby-theme-core/src/lib"

export default ({ html, date, title, image, location }: PostShowPageProps) => {
  return (
    <Layout style={{ padding: "24px" }} currentPath={location.pathname}>
      <Descriptions title={title}>
        <Descriptions.Item key="Publish Date" label="Publish Date">
          {date || "Not Listed"}
        </Descriptions.Item>
      </Descriptions>
      <div className="blog-post">
        <div style={{ maxWidth: 600 }}>{image}</div>
        <Divider dashed />
        <div className="blog-post-body">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </Layout>
  )
}
