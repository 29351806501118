import React, { ReactNode } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import { FluidObject } from "gatsby-image";
import Img from "gatsby-image"; // to take image data and render it
import "./blog-post.css";
import PostShowPage from "../components/PostShowPage";
import TrianglifySvg from "../components/TrianglifySvg";
import { PostSchema } from "../lib/";

const formatQueryData = ({ post }): PostSchema => {
  const imageSrc = get(post.image, "childImageSharp.fluid");
  return {
    ...post,
    image: imageSrc ? (
      <Img style={{ width: "100%", height: "100%" }} fluid={imageSrc} />
    ) : (
      <TrianglifySvg seed={post.slug} />
    ),
  };
};

export default ({ data, location }) => (
  <PostShowPage location={location} {...formatQueryData(data)} />
);

export const query = graphql`
  query($slug: String!) {
    post: posts(slug: { eq: $slug }) {
      html
      title
      image {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      date
      slug
    }
  }
`;
